let resourcesAreLoaded = false;
export default function init() {
  let scope = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : document;
  const chatUI = scope.querySelector("bn-convo");
  if (!chatUI) return;
  ensureResourcesAreLoaded();
}
function ensureResourcesAreLoaded() {
  if (resourcesAreLoaded) return;
  resourcesAreLoaded = true;
  const resources = document.getElementsByClassName("chat-ui-resource");
  for (const resource of resources) {
    if (resource.dataset.src) {
      resource.src = resource.dataset.src;
    } else if (resource.dataset.href) {
      resource.href = resource.dataset.href;
    }
  }
}